(function() {
    var DEFAULT_IMAGE_PATH = '/resources/imgs/imgs_new/common/noimage.png';

    var targetElements = document.querySelectorAll('[data-user-profile-image="true"]');
    if (targetElements.length === 0) {
        return;
    }

    if (!window.userProfileImageApi) {
        replaceImageSrc(DEFAULT_IMAGE_PATH);
        return;
    }


    var cookies = {};
    document.cookie.split(';').forEach(function(kv) {
        var separete = kv.trim().split('=');
        cookies[separete[0]] = separete[1];
    });

    // cookieに保持しているユーザー画像APIのURLを取得
    var cachedUrl = cookies.userProfileImageUrl ? decodeURIComponent(cookies.userProfileImageUrl) : null;

    if (cachedUrl) {
        replaceImageSrc(cachedUrl);
    } else {
        // responceURLがほしかったのでjQueryを使ってません
        var xhr = new XMLHttpRequest();
        var userImageUrl;
        xhr.onreadystatechange = function() {
            if (xhr.readyState !== 4) {
                return;
            }

            var statusCode = parseInt(xhr.status, 10);
            // リクエスト成功時のみ画像パスをcookieにセット
            if (statusCode >= 200 && statusCode < 400) {
              userImageUrl = xhr.responseURL;
              document.cookie = 'userProfileImageUrl=' + encodeURIComponent(xhr.responseURL) + '; max-age=' + 9000; // 15分キャッシュする
            } else {
              userImageUrl = DEFAULT_IMAGE_PATH;
            }

            replaceImageSrc(userImageUrl);
        };
        xhr.open('GET', window.userProfileImageApi, true);
        xhr.send();
    }

    function replaceImageSrc(url) {
      for(var i = 0; i < targetElements.length; i += 1){
        targetElements[i].src = url;
      }
    }
})();
