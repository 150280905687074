(function() {
    var hamburgerIcon = document.getElementById('js-gMenuHamburgerIcon');
    var gMenuContainer = document.getElementById('js-gMenuContainer');
    var gMenu = document.getElementById('js-gMenu');
    var site = document.querySelector('.js-site');
    var html = document.getElementsByTagName('html')[0];
    var body = document.getElementsByTagName('body')[0];

    if (hamburgerIcon && gMenuContainer && gMenu && site) {
        hamburgerIcon.addEventListener('click', function() {
            gMenuContainer.classList.add('gMenu__container--opened');
            gMenu.classList.add('gMenu--opened');
            site.classList.add('isMenuOpened');
            html.classList.add('noScroll');
            body.classList.add('noScroll');

            window.dataLayer.push({
              event: 'tap',
              logParams: JSON.stringify({
                module_name: 'header',
                section_id: 'hamburger_menu'
              })
            });
        });

        // メニューのコンテナ要素に、タップしたらメニューが閉じる処理を追加
        // コンテナ要素は画面いっぱいにスタイルされている前提
        gMenuContainer.addEventListener('click', function() {
            gMenuContainer.classList.remove('gMenu__container--opened');
            gMenu.classList.remove('gMenu--opened');
            site.classList.remove('isMenuOpened');
            html.classList.remove('noScroll');
            body.classList.remove('noScroll');
        });

        // メニュー内の要素をタップしてもメニューが閉じないようにイベントのバブリングを止める
        gMenu.addEventListener('click', function(e) {
            e.stopPropagation();
        });
    }
})();
