jQuery(function($, doc, window) {
	/**
	 * touchend時に関数を実行するjQueryプラグイン
	 *
	 * @class touchButton
	 * @param action {Function} touchend時に実行する関数
	 * @param action2 {Function} touchstart時に実行する関数
	 * @param action3 {Function} touchmove時に実行する関数
	 */
	$.fn.touchButton = function(action,action2,action3,options) {
		var touch = false,
			$this = $(this),

			flickStartCount = 0,
			activeBool = true,
			isMoving = false,
			startX = 0,
			startY = 0,
			endActionFlg;

		var c = $.extend({
			isReturn: true
		},options);

		var isReturn = c.isReturn;

		$(document).on(U.touchstart, this.selector, function(e) {
			var touch = U.mobile ? e.originalEvent.touches[0] : e;
			endActionFlg = true;

			startX = touch.pageX;
			startY =  touch.pageY;
			isMoving = true;

			if(action2) {
				action2(e);
			}
		})
		.on(U.touchmove, this.selector, function(e) {
			var touch = U.mobile ? e.originalEvent.touches[0] : e,
				touchpageX = touch.pageX,
				touchpageY = touch.pageY;


			if(isMoving) {
				var diffY = touchpageY - startY,
					diffX = touchpageX - startX;

				if(activeBool){
					if(diffY > 3 || diffY < -3)  {
						endActionFlg = false;
						activeBool = false;
						if(action3) {
							action3(e);
						}
					}
				}
			}
		})
		.on(U.touchend, this.selector, function(e) {
			flickStartCount = 0;
			activeBool = true;
			isMoving = false;

			if (endActionFlg) {
				if(action) {
					action(e);

					return isReturn;
				}
			}
		});
	};


	var setClass =  {
			active: function() {
				this.add('button','active');
			},
			over : function () {
				this.add('main a,footer a,.js_touchOver,.popup .after,.popup .action,.loginBtn,.button,.registrationBtn,.btn','touchOver');
			},
			add : function(elm,cName) {
				$(elm).touchButton(function(e){
					$(e.currentTarget).removeClass(cName);
				}
				,function(e){
					$(e.currentTarget).addClass(cName);
				},
				function(e){
					$(e.currentTarget).removeClass(cName);
				});
			}
		};
	setClass.active();
	setClass.over();
}(jQuery, document, window));