var login = (function($){
	var $popupForm = $('.popupForm');
	$('.popupForm .close').touchButton(function() {$popupForm.addClass('off');});

	$('.popup .after').touchButton(function() {$popupForm.addClass('off');});

	$('.login,.loginBtn').touchButton(function() {
		loginAction();
	});

	U.formValidation('.popupForm .formBox').init();
	$('.popupForm .registrationBtn').touchButton(function() {
		registration();
	});


	(function() {
		if (location.search.indexOf('auth') < 1) {
			return;
		}
		if (isOther) {
			$popupForm.removeClass('off');
			return;
		}

		if (location.search.indexOf('popup') >= 1) {
			$popupForm.removeClass('off');
		}
	}());

	var postCount = 0;
	var registration = function() {
		U.formValidation('.popupForm .formBox',{
				errorOutput: '.popupForm .box .error',
				complate: function(e){
					var data = {
						xtoken: xtoken,
						nickName: e.nickName,
						birthday: e.birthday,
						gender: e.gender
					};

					if(postCount === 0) {
						$.ajax({
							type: 'post',
							url: '/register/user?ambv=br',
							data: data
						})
						.done(function(data) {
							location.href = location.pathname;
							return false;
						}).fail(function(xmlHttpRequest, textStatus, errorThrown) {
							if(xmlHttpRequest.status === 400) {
								e.$error.html('項目を正しく入力してください');
								postCount = 0;
							} else if (xmlHttpRequest.status === 401) {
								var url = encodeURIComponent(location.pathname + (location.search ? location.search : ''));
								window.location = '/auth?state=' + url + (url.indexOf('%3F') > 0 ? '%26' : '%3F') + 'popup%26ambv=br%26back=' + url;
							} else {
								e.$error.html('登録に失敗しました。<br>後ほどお試しください');
							}
						});
					}
					postCount++;
				}
		}).check();
	},

	loginAction = function() {
		if (isGuest) {
			if (isApproved) {
				$popupForm.removeClass('off');
			} else {
				var url = encodeURIComponent(location.pathname + (location.search ? location.search : ''));
				if (isOther) {
					window.location = '/auth?state=' + url + '&back=' + url;
				} else {
					window.location = '/auth?state=' + url + (url.indexOf('%3F') > 0 ? '%26' : '%3F') + 'popup%26ambv=br%26back=' + url;
				}
			}
		}
	};
})(jQuery);
