(function(window, doc) {

/**
 * ページ全体で使う関数の追加
 *
 * @class U
 */
window.U = (function($) {

	var U = function() {
		this.ua = navigator.userAgent;
		this.iphone = this.ua.match(/iPhone OS ([0-9])/g);
		this.ipad = this.ua.match(/iPad; U?;?\s?CPU OS ([0-9])/g);
		this.ios = this.iphone || this.ipad;
		this.fullscreen = window.navigator.standalone;
		this.isAppli = this.ua.indexOf("CaWebApp");
		this.android = this.ua.match(/Android ([0-9]\.[0-9])/g);
		this.androidChrome = this.android && this.ua.indexOf('Chrome');
		this.lastWidth = 0;
		this.mobile = (this.android || this.iphone || this.ipad) !== null;

		this.touchstart = this.mobile ? 'touchstart' : 'mousedown';
		this.touchend = this.mobile ? 'touchend' : 'mouseup';
		this.touchmove = this.mobile ? 'touchmove' : 'mousemove';
	};

	//----------------------------------------------------------------------
	// sessionStorage
	//----------------------------------------------------------------------
	/**
	 * sessionStorageへ保存する
	 *
	 * @method sessionStorage
	 * @param key {String} 保存したいsessionStorageのkey
	 * @param data {String} そのkeyに保存したい値
	 */
	U.prototype.saveSessionStorage = function(key, data) {
		try {
			if(typeof(Storage)!=="undefined") {
				if (data) {
					sessionStorage[key] = JSON.stringify(data);
				} else {
					sessionStorage[key] = null;
				};
			};
		} catch (e) {
		}
	};

	/**
	 * sessionStorageの情報を使用する
	 *
	 * @method findSessionStorage
	 * @param key {String} 使用したいsessionStorageのkey
	 */
	U.prototype.findSessionStorage = function(key) {
		try {
			if (typeof(Storage) !== "undefined") {
				var data = sessionStorage[key];
				if (data) {
					return JSON.parse(data);
				};
			};
			return null;
		} catch (e) {
		}
	};

	/**
	 * 既に保存されているsessionStorageの情報を削除する
	 *
	 * @method removeSessionStorage
	 * @param key {String} 削除したいsessionStorageの名前
	 */
	U.prototype.removeSessionStorage = function(key) {
		try {
			if (typeof(Storage) !== "undefined") {
				sessionStorage.removeItem(key);
			};
		} catch (e) {
		}
	};

	//----------------------------------------------------------------------
	// localStorage
	//----------------------------------------------------------------------
	/**
	 * localStorageへ保存する
	 *
	 * @method saveLocalStorage
	 * @param key {String} 保存したいlocalStorageのkey
	 * @param value {String} そのkeyに保存したい値
	 */
	U.prototype.saveLocalStorage = function(key, value) {
		try {
			if (typeof(Storage) !== "undefined") {
				if (value) {
					localStorage[key] = JSON.stringify(value);
				} else {
					localStorage[key] = null;
				}
			}
		} catch (e) {
		}
	};

	/**
	 * localStorageの情報を使用する
	 *
	 * @method findLocalStorage
	 * @param key {String} 使用したいlocalStorageのkey
	 */
	U.prototype.findLocalStorage = function(key) {
		try {
			if (typeof(Storage) !== "undefined") {
				var data = localStorage[key];
				if (data) {
					return JSON.parse(data);
				};
			};
			return null;
		} catch (e) {
		}
	};

	/**
	 * 既に保存されているlocalStorageの情報を削除する
	 *
	 * @method removeLocalStorage
	 * @param key {String} 削除したいLocalStorageの名前
	 */
	U.prototype.removeLocalStorage = function(key) {
		try {
			if (typeof(Storage) !== "undefined") {
				localStorage.removeItem(key);
			};
		} catch (e) {
		}
	};

	//----------------------------------------------------------------------
	// Validation
	//----------------------------------------------------------------------
	/**
	 * 誕生日が正しいフォーマットかチェックする（正 : 1985-07-01）
	 *
	 * @method birthdayValidation
	 * @param str {String} 誕生日フォーマットの文字列
	 * @return {Boolean} 正しいフォーマットの場合 true ソレ以外 false
	 */
	U.prototype.birthdayValidation = function(str) {
		var valAry = str.split('-');

		//もしも2014-01-01のような形式の場合
		if(valAry.length === 3) {
			//ただし1000000-10000-10000のような変な形式もとうしてしまうので更に条件分岐
			var year = valAry[0],
				month = valAry[1],
				day = valAry[2],
				//空の場合
				isEmpty = year=== '' || month === '' || day === '',
				//yearが四桁 or monthが二桁以上 or 日付が二桁以上 のいずれかに当てはまった場合
				isLength = year.length !== 4 || month.length > 2 || day.length > 2,
				//月or日が一桁 かつ 0の時
				isZero = month.length === 1 && month === '0' || day.length  === 1 && day  === '0';

			if(isLength || isEmpty || isZero) {
				return false;
			};

			return true;
		} else {
			return false;
		};
	};

	/**
	 * 誕生日が空の場合1987-01-01を返し、空以外の場合は引数の値をそのまま返す
	 *
	 * @method checkBirthday
	 * @param tag {String} 誕生日フォーマットの文字列
	 */
	U.prototype.checkBirthday = function(tag) {
		return tag == '' ? '1975-01-01' : tag;
	};

	/**
	 * 変数objs内のinputがchangeされた時に名前のバリデーション
	 *
	 * @method tag
	 * @method addValidationForName
	 */
	U.prototype.addValidationForName = function(tag) {
		var objs = '#last, #first, #lastPartner, #firstPartner, #furiganaLastPartner, #furiganaFirstPartner, #furiganaFirst, #furiganaLast',
			matchKanji = /[\u4E00-\u9FCF|\u3041-\u309F|々]*/,
			matchKana = /[\u3041-\u309F]*/;
			tag = (tag !== undefined) ? ',' + tag : '';

		$(objs + tag).change(function() {
			var matchType = jQuery(this)[0].className.indexOf('js_validationKanji') !== -1 ? matchKanji : matchKana;
			var n = "";
			var s = $(this).val();
			if (matchType ==  matchKanji) {
				if (s.length > 5) {
					s = s.substring(0, 5);
					$(this).val(s);
				}
			}
			for (var c in s) {
				n += s[c].match(matchType);
				if (n.length == 8) {
					break;
				}
			}
			if (n == "") {
				for (var i = 1; i < 9; i++) {
					n += s.substring(([i] - 1), [i]).match(matchType);
				}
			}
			$(this).val(n);
		});
	};

	U.prototype.day = {
		set: function($this, year, month){
			var selectedNum = Number($this.val()),
				day = this.num(Number(year),Number(month));

			$this.empty();

			for(var i = 1; i <= day; i++){
				var dayStr = String(i).length < 2 ? '0' + i : i;
					selected = selectedNum === i ? 'selected' : '';

			   $this.append('<option value="' + dayStr + '"' + selected + '>' + i + '日</option>');
			};

		},
		num: function(year,month){
			var year = year,
				month = month;

			return month === 2 ? (year % 4 === 0 && year % 100 === 0 && year % 400 === 0) || (year % 4 === 0 && year % 100 !== 0) ? 29 : 28 :
			 (month === 4 || month === 6 || month === 9 || month === 11) ? 30 : 31
		}
	};



	/**
	 * フォームのバリデーション（ログイン時）
	 * formValidation.init - 入力時の規制設定
	 * formValidation.check - 決定時のチェック
	 * @param  {String} content formが入っているdlを囲っている
	 * @param  {Object} options
	 		* check {Array} チェックしたい項目（default['nickname','birthday']）
	 		* complate {Fucntion} チェックして問題なければcomplateを実行
	 		* error {String} チェックして問題があった場合に表示するエリア
	 */
	U.prototype.formValidation = function(content,options){
		var options = $.extend({
				check: ['nickname','birthday'],
				compate: '',
				errorOutput: ''
			}, options),

			$content = $(content),
			errorMsg = {
				nickname: '正しく入力してください',
				birthday: '正しく入力してください'
			},
			isAllEmpty = false;

		return {
			init: function(){
				// 入力しなおした場合エラーテキストエリアを空にする
				$content.find('input').change(function() {
					var $this =$(this),
						name = $this.parents('dl').prop('class').replace('off');
					if($this.val() !== '') {
						$content.find('.error_' + name).text('');
					};
				});

				//ニックネームが30文字以上入力されていた時 強制的に30文字にする
				$content.find('.input_nickname input').change(function() {
					var $this =$(this),
						nameStr = $this.val(),
						maxNum = 30,
						maxNameStr = nameStr.substring(0, maxNum)

					$this.val(nameStr.length >= maxNum ? maxNameStr : nameStr);
				});
			},
			check: function(){
				for (var i = options.check.length - 1; i >= 0; i--) {
					var name = options.check[i],
						$check = $content.find('.' + name),
						claName = $check.prop('class'),
						isHiddenContent = claName.indexOf('off') >= 0;

						$input = $check.find('input'),
						$error = $content.find('.error_' + name);

					if(!isHiddenContent) {
						var isEmpty = false;

						$input.each(function(){
							if($(this).val() == '') {
								isEmpty = true;
							};
						});

						if(isEmpty) {
							$error.text(errorMsg[name]);
							isAllEmpty = true;
						}
					}
				};

				var $birthday = $content.find('> .birthday'),
					$birthdayInput = $birthday.find('input'),
					$birthdaySelect = $birthday.find('select'),
					$birthdayError = $content.find('.error_birthday'),
					birthdayVal = $birthdayInput.val(),
					isBirthdayHidden = $birthday.prop('class').indexOf('off') >= 0;

				if(birthdayVal !== '' && !isBirthdayHidden) {
					if(!window.U.birthdayValidation(birthdayVal)) {
						var dateAry = window.U.checkBirthday('').split('-'),
							dateObj = {
								Year : dateAry[0],
								Month : dateAry[1],
								Day : dateAry[2]
							};

						for(date in dateObj) {
							var $select = $birthday.find('.select' + date + ' option[value=' + dateObj[date] + ']');
							if($select.size() !== 0) {
								$select[0].selected= true;
							};
						};

						$birthdayInput.addClass('off');
						$birthdaySelect.removeClass('off');
						selectBox();

						$birthdayError.html('再度入力して下さい');
						$birthdaySelect.on('change',function() {
							selectBox();
						});

						return false;
					};
				};

				function selectBox() {
					var year = $birthday.find('.selectYear').val(),
						month = $birthday.find('.selectMonth').val(),
						day = $birthday.find('.selectDay').val();
					$birthday.find('input').val(year + '-' + month + '-' + day);
				}

				if(!isAllEmpty) {
					$content.find('[class^=error]').text('');
					if(typeof options.complate === 'function') {
						options.complate({
							nickName: $content.find('.input_nickname input').val(),
							birthday: $content.find('.birthday input').val(),
							gender: $content.find("#loginGenderWoman")[0].checked ? "FEMALE" : "MALE",
							$error: $(options.errorOutput)
						});
					}
				}
			}
		}
	};


	return new U();
}(jQuery));


/**
 * add State
 */
var addState = (function($){
		var $banner = $('.addState a');

		$banner.each(function(index, el) {
			var $this = $(this),
				href = $this.prop('href'),
				resettingsHref;
				if(href.indexOf('?') > 0) {
					resettingsHref = href + '&state=' + location.pathname
				} else {
					resettingsHref = href + '?state=' + location.pathname
				}
				$this.prop('href',resettingsHref);
		});
})(jQuery);

/**
 * Ameba app link open safari
 */
var appLinkOpenSafari = (function($){
	$(function() {
		if(typeof isCaWebapp !== 'undefined'){
			if(isCaWebapp) {
				var a = '#header a:not([href^=#]),main a:not([href^=#]):not(.noneBr),#footer a:not([href^=#]),.sb-slidebar a,.main a:not([href^=#]):not(.noneBr),#main a:not([href^=#]):not(.noneBr)';

				$(a).each(function() {
					var href = $(this).prop('href');
					if (href.indexOf('javascript') !== -1) {
						return true;
					}
					if (href.indexOf('ambv=br') !== -1) {
						return true;
					}
					href = href.indexOf('?') !== -1 ? href + '&ambv=br' : href + '?ambv=br';

					$(this).attr('href', href);
				});
			}
		}
	});
})(jQuery);

var saveMenuLocalStorage = (function() {
	var pathname = window.location.pathname;
	var pathnameAry = pathname.slice(1).split('/');
	var isMenuUrl =
		pathnameAry.length === 2 &&
		pathnameAry[0] === 'menu' &&
		typeof menuId !=='undefined' && !isNaN(menuId);


	if((isMenuUrl || (typeof isAccessHistory !== 'undefined' && isAccessHistory)) && menuTitle !== '') {
		var ACCESS_HISTORY_MAX_LENGTH = 20;

		var accessHistoryStorage = U.findLocalStorage('accessHistory') === null ? [] : U.findLocalStorage('accessHistory');

		var overlappingNumber = (function() {
			for(var i = 0; i < accessHistoryStorage.length;i++) {
				if(accessHistoryStorage[i].menuId === menuId) {
					return i;
				}
			}
		})();
		if(overlappingNumber !== undefined) accessHistoryStorage.splice(overlappingNumber, 1);

		accessHistoryStorage.unshift({});
		accessHistoryStorage[0].menuId = menuId;
		accessHistoryStorage[0].title = menuTitle;
		accessHistoryStorage[0].fortunetellerName = adviserName;
		accessHistoryStorage[0].providerName = providerName;
		accessHistoryStorage[0].thumbnailImageUrl = thumbnailImageUrl;
		accessHistoryStorage[0].genre = genre;
		accessHistoryStorage[0].genreName = genreName;
		accessHistoryStorage[0].isGold = isGold;
		accessHistoryStorage[0].contentType = typeof contentType !== 'undefined' ?  contentType : '';
        accessHistoryStorage[0].baseCost = typeof regularPrice !== 'undefined' ?  regularPrice : '';
        accessHistoryStorage[0].cost = typeof price !== 'undefined' ?  price : '';
        accessHistoryStorage[0].isMatch = isMatch;

		if(accessHistoryStorage.length > ACCESS_HISTORY_MAX_LENGTH) {
			accessHistoryStorage.pop()
		}

		U.saveLocalStorage(
			'accessHistory',
			accessHistoryStorage
		);
	}
})();

  /**
   * LP から遷移した時のヘッダーロゴのリンク先の出しわけ処理
   * - 非会員 LP, プレミアム占い師 LP でローカルストレージに `df_LP_REF` というキーで `location.pathname` を保存
   * - LP -> お試し占い -> LP のループを担保するためにメニューページとお試し占い結果ページでヘッダーロゴのリンクをローカルストレージの値に変更する
   * - メニューページとお試し占い結果ページ以外に遷移した（期待するループから外れた）時点でローカルストレージを削除
   */
  (() => {
    const storageKey = 'df_LP_REF';
    const lpPath = '/lp-premium';
    // お試し占い結果ページかどうか
    const isTrialResultPage = /\/fortune\/(free|trial)\/(nonmember\/)?\d+$/.test(location.pathname);

    if (location.pathname.startsWith('/menu/') || isTrialResultPage) {
      const headerLogoAnchor = document.getElementById('js-header-anchor');

      // aries 側で JSON.stringify してから保存しているので復元するときに JSON.parse が必要
      const dfLpRef = JSON.parse(localStorage.getItem(storageKey));

      // ローカルストレージからパスが取得できて LP のパスと一致する、かつリンク要素が存在した場合にのみリンク先変更する
      if (dfLpRef != null && dfLpRef.startsWith(lpPath) && headerLogoAnchor != null) {
        headerLogoAnchor.href = dfLpRef;
        localStorage.setItem('df_IS_FROM_LP_FOR_LINEUP', true); // 豪華なラインナップのLP遷移確認用の値

        if (isTrialResultPage) {
          localStorage.removeItem(storageKey);
        }
      }
    } else if (localStorage.getItem(storageKey) != null) {
      localStorage.removeItem(storageKey);
    }
  })();
}(window, document));


/**
 *
 ** @method Object.assign
 *  see: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
 */
if (typeof Object.assign != 'function') {
	(function () {
		Object.assign = function (target) {
			'use strict';
			if (target === undefined || target === null) {
				throw new TypeError('Cannot convert undefined or null to object');
			}

			var output = Object(target);
			for (var index = 1; index < arguments.length; index++) {
				var source = arguments[index];
				if (source !== undefined && source !== null) {
					for (var nextKey in source) {
						if (Object.prototype.hasOwnProperty.call(source, nextKey)) {
							output[nextKey] = source[nextKey];
						}
					}
				}
			}
			return output;
		};
	})();
}
