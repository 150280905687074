(function($){
    var $updatePartnerButton = $('.js-update-partner'),
        $deletePartnerButton = $('.js-delete-partner');
    const apiPath = "/api/profile/";


    $updatePartnerButton.off('.updatePartner');
    $updatePartnerButton.on('click.updatePartner',function() {
        var profileId = $(this).data().partner_profile_id;
        if(profileId) {
            updatePartner(profileId);
        }
        return false;
    });

    $deletePartnerButton.off('.deletePartner');
    $deletePartnerButton.on('click.deletePartner',function() {
        var profileId = $(this).data().partner_profile_id;
        if(profileId) {
            deletePartner(profileId);
        }
        return false;
    });

    function updatePartner(profileId) {
        var url = apiPath + 'partner/' + profileId + '/favo';
        dataSend("PUT",url);
    }

    function deletePartner(profileId) {
        var url = apiPath + 'partner/' + profileId;
        dataSend("DELETE",url);
    }

    function dataSend(method,url) {
        $.ajax({
            url: url,
            type: method,
            headers: {
                'X-CSRF-TOKEN': xtoken
            },
            success: function(result) {
                window.location.href = "/profile";
            },
            error : function (XMLHttpRequest, textStatus, errorThrown) {
                throw new Error(XMLHttpRequest.status);
            }
        });
    }
})(jQuery);
